import {
  bgLogin,
  bgLoginGreen,
  bgLoginPurple,
  bgUnauthenBlue,
  bgUnauthenGreen,
  bgUnauthenPurple,
  searchBlueIcon,
  searchGreenIcon,
  searchPurpleIcon,
  trashIcon,
  shareIcon,
  exportIcon,
  exportPPTX,
  exportTemplates
} from '@/utils/images'

export const USER = 'USER'
export const USER_TOKEN = 'USER_TOKEN'
export const REFRESH_TOKEN = 'REFRESH_TOKEN'
export const THEME_COLOR = 'THEME_COLOR'
export const BLUE = 'blue'
export const GREEN = 'green'
export const PURPLE = 'purple'
export const BLUE_NUM = 0
export const GREEN_NUM = 1
export const PURPLE_NUM = 2
export const TOKEN_EXPIRED = 99999
export const UNAUTHORIZED = 'Unauthorized'
export const FORBIDDEN = 'FORBIDDEN'
export const FIREBASE_TOKEN = 'FIREBASE_TOKEN'
export const ACTIONS = [
  { id: 1, label: 'shareIcon', icon: shareIcon },
  { id: 2, label: 'exportIcon', icon: exportIcon },
  { id: 3, label: 'deleteIcon', icon: trashIcon },
]
export const EXPORTS = [
  { id: 1, label: 'プレーンファイルで出力', icon: exportPPTX },
  { id: 2, label: 'テンプレートを使用して出力', icon: exportTemplates },
]
export const HEADER_MENUS = [
  {
    id: 1,
    title: 'What is InFolio?',
    link: '/',
    newTab: false,
    classes: 'scroll--what',
  },
  {
    id: 2,
    title: 'Features',
    link: '/',
    newTab: false,
    classes: 'scroll--feature',
  },
  {
    id: 3,
    title: 'Values',
    link: '/',
    newTab: false,
    classes: 'scroll--value-detail',
  },
  {
    id: 4,
    title: 'News',
    link: '/',
    newTab: false,
    classes: 'scroll--news-detail',
  },
  // {
  //   id: 5,
  //   title: 'Contact Us',
  //   link: '/',
  //   newTab: false,
  //   classes: 'scroll--contact'
  // },
  {
    id: 6,
    title: 'FAQ',
    link: 'https://trail-coneflower-3b0.notion.site/InFolio-FAQ-80da477d21d44ff687be27025faa7a8e',
    newTab: true,
    classes: '',
  },
]
export const INVITED = 0
export const REGISTERED = 1
export const ADMIN = 0
export const DEACTIVE = 0
export const ACTIVE = 1
export const RECEIVE = 1
export const NOT_RECEIVE = 0
export const PERMISSION_OWNER = 2
export const PERMISSION_EDIT = 1
export const PERMISSION_VIEW = 0
export const PERMISSION_DELETE = -1

export const BLUE_THEME = {
  color: '#3a7cab',
  lightColor: '#7cb1d9',
  bgLogin: bgLogin,
  bgUnauthen: bgUnauthenBlue,
  searchIcon: searchBlueIcon,
}
export const GREEN_THEME = {
  color: '#00a88f',
  lightColor: '#79c7bb',
  bgLogin: bgLoginGreen,
  bgUnauthen: bgUnauthenGreen,
  searchIcon: searchGreenIcon,
}
export const PURPLE_THEME = {
  color: '#7f60db',
  lightColor: '#ab91f6',
  bgLogin: bgLoginPurple,
  bgUnauthen: bgUnauthenPurple,
  searchIcon: searchPurpleIcon,
}
export const FORMAT_DATE = 'yyyy/MM/dd'
export const FORMAT_DATE_CAPITAL = 'YYYY/MM/DD'
export const TIMEOUT = 120000 //milisecond = 120s = 2 minutes
export const DEFAULT_ITEMS_PER_PAGE_BENCHMARK = 10
export const DEFAULT_ITEMS_PER_PAGE_RESEARCH = 5
export const DEFAULT_ITEMS_PER_PAGE_SHARED = 5
export const DEFAULT_PROMPT = `#キーワードと期間で絞り込んで記事を取得してください。
抽出された記事の内容を確認した上で、developer層に関連しそうなニュースをピックアップして100文字以内で要約してください。`
export const BENCH_MARK_KEYWORDS = 2
export const BENCH_MARK_BUSINESS = 1
export const SELF = 'self'

export const EXPORTS_TYPE = [
  { id: 0, type: 'lib' },
  { id: 1, type: 'benchmark' },
  { id: 2, type: 'piecefolio' },
]