import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { setUserToken, setRefreshToken, setUser, getUser as getUserLocal } from '@/utils/localData'
import { BLUE, PURPLE, GREEN } from '@/utils/constant'
import authApi from '@/api/auth'
import userApi from '@/api/user'
import useLogout from '@/hooks/useLogout'
import { changeTheme } from '@/utils/function'

export const loginUser = createAsyncThunk('user/login', async ({ email, password }, thunkAPI) => {
  try {
    const data = { email: email, password: password }
    const user = await authApi.login(data)
    if (!user.errors) {
      setUserToken(user.accessToken)
      setRefreshToken(user.refreshToken)
      setUser({
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        roleId: user.roleId,
        status: user.status,
        theme: user.theme,
      })
      return user
    }
  } catch (e) {
    return thunkAPI.rejectWithValue({ errors: e.errors ? e.errors : e })
  }
})

export const logoutUser = createAsyncThunk('user/logout', async (_, thunkAPI) => {
  try {
    await authApi.logout()
  } catch (e) {
    return thunkAPI.rejectWithValue({ errors: e.errors ? e.errors : e })
  }
})

export const getUser = createAsyncThunk('user/get', async (_, thunkAPI) => {
  try {
    const user = await userApi.getUser()
    if (user) return user
    else {
      return thunkAPI.rejectWithValue({
        errors: { message: 'この社員トは存在しないか、既に削除されています。' },
      })
    }
  } catch (e) {
    if (e && e.errors) {
      return thunkAPI.rejectWithValue({ errors: e.errors ? e.errors : e })
    } else {
      return thunkAPI.rejectWithValue({ errors: { message: 'Something went wrong!!!' } })
    }
  }
})

export const updateUser = createAsyncThunk('user/update', async (data, thunkAPI) => {
  try {
    let user = await userApi.updateUser(data)
    if (!user.errors) {
      setUser({
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        roleId: user.roleId,
        status: user.status,
        theme: user.theme,
      })
      return user
    }
  } catch (e) {
    if (e && e.errors) {
      return thunkAPI.rejectWithValue({ errors: e.errors ? e.errors : e })
    } else {
      return thunkAPI.rejectWithValue({ errors: { message: 'Something went wrong!!!' } })
    }
  }
})

export const updatePassword = createAsyncThunk('user/update-password', async (data, thunkAPI) => {
  try {
    let user = await userApi.updatePassword(data)
    if (!user.errors) {
      return user
    }
  } catch (e) {
    return thunkAPI.rejectWithValue({ errors: e.errors ? e.errors : e })
  }
})

export const verifyEmail = createAsyncThunk('user/verify-email', async (data, thunkAPI) => {
  try {
    const currentUser = getUserLocal() || { firstName: '', lastName: '' }
    let user = await userApi.verifyEmail(data)
    if (!user.errors) {
      setUserToken(user.accessToken)
      setRefreshToken(user.refreshToken)
      changeTheme(BLUE)
      setUser({
        email: user.email,
        status: user.status,
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
      })
      return { ...user, receiveNotification: 1, theme: 0 }
    }
  } catch (e) {
    if (e?.errors?.errors) {
      return thunkAPI.rejectWithValue({ errors: e.errors.errors })
    }
    return thunkAPI.rejectWithValue({ errors: e.errors ? e.errors : e })
  }
})

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    username: '',
    email: '',
    isAdmin: 0,
    isProcessing: false,
    isLoaded: false,
    isSuccessfully: false,
    successMessage: '',
    isError: false,
    errors: {},
    isFetching: false,
    isSigning: false, // on signing and signing-out
    isProcessingChangePass: false,
    isSuccessfullyChangePass: false,
    successMessageChangePass: '',
    errorsChangePass: {},
  },
  reducers: {
    clearState: (state) => {
      state.isError = false
      state.isProcessing = false
      state.isSuccessfully = false
      state.errors = {}
      state.successMessage = ''
      state.isFetching = false
      state.isSigning = false
      state.isProcessingChangePass = false
      state.isSuccessfullyChangePass = false
      state.successMessageChangePass = ''
      state.errorsChangePass = {}
      return state
    },
    clearIsLoaded: (state) => {
      state.isLoaded = false
      return state
    },
  },
  extraReducers: {
    [loginUser.fulfilled]: (state, { payload }) => {
      const color = payload.theme == 1 ? GREEN : payload.theme == 2 ? PURPLE : BLUE
      state = { ...payload }
      state.isAdmin = payload.roleId == 0
      state.isSigning = false
      state.isSuccessfully = true
      changeTheme(color)
      return state
    },
    [loginUser.rejected]: (state, { payload }) => {
      state.isSigning = false
      state.isError = true
      state.errors = payload.errors
    },
    [loginUser.pending]: (state) => {
      state.isSigning = true
    },
    [logoutUser.fulfilled]: (state, { payload }) => {
      useLogout()
    },
    [logoutUser.rejected]: (state, { payload }) => {
      state.isSigning = false
      state.isError = true
      state.errors = payload.errors
    },
    [logoutUser.pending]: (state) => {
      state.isSigning = true
    },
    [updateUser.fulfilled]: (state, { payload }) => {
      const color = payload.theme == 1 ? GREEN : payload.theme == 2 ? PURPLE : BLUE
      state = { ...state, ...payload }
      state.successMessage = payload.message
      state.isProcessing = false
      state.isSuccessfully = true
      changeTheme(color)
      return state
    },
    [updateUser.rejected]: (state, { payload }) => {
      state.isProcessing = false
      state.isSuccessfully = false
      state.isError = true
      state.errors = payload.errors
    },
    [updateUser.pending]: (state) => {
      state.isProcessing = true
    },
    [getUser.fulfilled]: (state, { payload }) => {
      const color = payload.theme == 1 ? GREEN : payload.theme == 2 ? PURPLE : BLUE
      state = { ...state, ...payload }
      state.isAdmin = payload.roleId == 0
      state.isProcessing = false
      state.isLoaded = true
      changeTheme(color)
      return state
    },
    [getUser.rejected]: (state, { payload }) => {
      state.isProcessing = false
      state.isError = true
      state.errors = payload.errors
    },
    [getUser.pending]: (state) => {
      state.isProcessing = true
    },
    [updatePassword.fulfilled]: (state, { payload }) => {
      state.successMessageChangePass = payload.message
      state.isProcessingChangePass = false
      state.isSuccessfullyChangePass = true
      return state
    },
    [updatePassword.rejected]: (state, { payload }) => {
      state.isProcessingChangePass = false
      state.isError = true
      state.isSuccessfullyChangePass = false
      state.errorsChangePass = payload.errors
    },
    [updatePassword.pending]: (state) => {
      state.isProcessingChangePass = true
    },
    [verifyEmail.fulfilled]: (state, { payload }) => {
      state = { ...state, ...payload }
      state.isProcessing = false
      state.isSuccessfully = true
      state.isFetching = false
      return state
    },
    [verifyEmail.rejected]: (state, { payload }) => {
      state.isProcessing = false
      state.isFetching = false
      state.isError = true
      state.isSuccessfully = false
      state.errors = payload.errors
    },
    [verifyEmail.pending]: (state) => {
      state.isProcessing = true
      state.isFetching = true
    },
  },
})

export const { clearState, clearIsLoaded } = userSlice.actions

export const userSelector = (state) => state.user
