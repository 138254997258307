import React, { useState, useEffect } from 'react'
import {
  FileUploadComponent
} from '@/components'
import { Button, Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom'
import styles from './manager.module.css'
import toast from 'react-hot-toast';
import { getUser } from '@/utils/localData'
import { ADMIN } from '@/utils/constant'
import { useNavigate } from 'react-router-dom'
import { getPieceSetting } from '@/redux/modules/piecefolio'
import { selectTemplateLib, selectTemplateBM, saveTemplateLib, saveTemplateBMPiece } from '@/redux/modules/manager'
import { membersSelector, clearState } from '@/redux/modules/members'
import { useSelector, useDispatch } from 'react-redux'
import templateApi from '@/api/template';
import PieceSettingItem from './PieceSettingItem';
import { NOT_FOUND } from '@/utils/message'
import MemberTable from '../Members/MemberTable'
import InviteMember from '../Members/InviteMember'

function index() {
  const currentUser = getUser()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const dummy = {
    title: 'タイトル',
    sitesData: ['www.website.com', 'www.mypage.com'],
    keywordsData: ['Keyword', 'word'],
  }

  const templateFromStore = useSelector(selectTemplateLib)
  const templateBMFromStore = useSelector(selectTemplateBM)

  const [showSendMailDialog, setShowSendMailDialog] = useState(false)
  const [pieceSettings, setPieceSettings] = useState([]); 
  const [sites, setSites] = useState([]); 
  const [keywords, setKeywords] = useState([]); 
  const [isProcessing, setIsProcessing] = useState(false);
  const [fileExists, setFileExists] = useState(false);

  const onToggleSendMailDialog = (e) => {
    setShowSendMailDialog(!showSendMailDialog)
  }

  const handleCloseSendMailDialog = (e) => {
    e.stopPropagation()
    setShowSendMailDialog(false)
  }

  useEffect(() => {
    const controller = new AbortController()

    const checkFileExists = async () => {
        const response = await templateApi.checkFileExists();
        dispatch(saveTemplateLib(response.library_template))
        dispatch(saveTemplateBMPiece(response.benchmark_template))
    }

    checkFileExists() 

    return () => {
      controller.abort()
    }
  }, []);

  useEffect(() => {
    if (currentUser && currentUser?.roleId != ADMIN) {
      navigate('/aisearch')
    }
  }, [])

  useEffect(() => {
    const controller = new AbortController()
    const fetchData = async () => {
      try {
        const result = await dispatch(getPieceSetting({ signal: controller.signal })).unwrap()
       
        if (result) {
          setPieceSettings(result);
        }
      } catch (error) {
        console.error('Failed to fetch piece setting:', error)
      }
    }

    fetchData()

    return () => {
      controller.abort()
    }
  }, [])

  useEffect(() => {
    setFileExists({
      library_template: templateFromStore,
      benchmark_template: templateBMFromStore
    })
  }, [templateFromStore, templateBMFromStore])

  const { isError, errors, isSuccessfully, successMessage } = useSelector(membersSelector)

  useEffect(() => {
    if (isSuccessfully) {
      if (successMessage)
        toast.success(successMessage, {
          className: 'custom-toast',
        })
      dispatch(clearState())
    }
    if (isError) {
      if (errors?.status === 403) {
        navigate('/aisearch')
      } else {
        const errs = transformErrors(errors)
        errs.forEach((er) => toast.error(er.message))
        dispatch(clearState())
      }
    }
  }, [isSuccessfully, isError])

  return (
    <div className={styles.container}>
      <div className={styles.managerHeader}>
        <label className={styles['color-theme']}>
          企業管理設定
        </label>
      </div>
      <Box className={styles.formContainer} mb={10}>
        <h3 className={`color-theme`}>ユーザー管理設定</h3>
        <MemberTable />
        <InviteMember />
      </Box>

      <Box>
        <div>
          <h3 className={`color-theme`}>PieceFolio設定</h3>

          <Box mb={10}>
            {pieceSettings.length > 0 ? (
              pieceSettings.map((setting, index) => (
                <PieceSettingItem
                  key={setting.piece_setting_id}
                  title={setting.title}
                  sitesData={setting.sites}
                  keywordsData={setting.keywords}
                />
              ))) : (
                <p className={styles.notFoundText}>{NOT_FOUND}</p>
              )
            }
          </Box>

          {/* <Box mb={2} className={styles.pieceSettingItem}>
            <h6>内容変更</h6>
            <Button
              className={styles.sendMailBtn}
              variant={'contained'}
              // onClick={savePiece}
              onClick={onToggleSendMailDialog}
              disabled={isProcessing}
            >
              {isProcessing ? '保存中...' : 'メールを送信'}
            </Button>
          </Box> */}
        </div>

        <div>
          <h3 className={`color-theme`}>PowerPointテンプレート設定</h3>
          <Link target='_blank'
                  to='https://trail-coneflower-3b0.notion.site/a0d8cbf1a1154cafa485929e9aea9c03?pvs=4'>設定方法について</Link>

          <FileUploadComponent label="AI検索" type="library" fileNameExist={fileExists.library_template}/>
          <FileUploadComponent label="ベンチマーク調査／PieceFolio" type="benchmark" fileNameExist={fileExists.benchmark_template}/>
        </div>
      </Box>

      {/* <SendMail
        show={showSendMailDialog}
        handleClose={handleCloseSendMailDialog}
        title={"メールを送信"}
      /> */}
    </div>
  );
};

export default index;
