import React, { useState, useEffect } from 'react'
import { Box } from '@mui/material';
import styles from './manager.module.css'

function PieceSettingItem({title='タイトル', sitesData, keywordsData}) {
  return (
    <div className={styles.pieceSettingContain}>
      <h6 className={styles.pieceSettingTitleItem}>{title || 'タイトル'}</h6>
      <Box mb={2} className={styles.pieceSettingItem}>
        <span className={styles.itemTitle}>WEBサイト</span>
        <Box className={styles.siteList}>
          {sitesData.map((val, index) => (
            <Box key={`site-${val}-${index}`} className={styles.item}>
              <p className="mb-0">{val}</p>
            </Box>
          ))}
        </Box>
        
        <span className={styles.itemTitle}>キーワード</span>
        <Box className={styles.keywordList}>
          {keywordsData.map((val, index) => (
            <Box key={`keyword-${val}-${index}`} className={styles.item}>
              <p className="mb-0">{val}</p>
            </Box>
          ))}
        </Box>
      </Box>
    </div>
  );
};

export default PieceSettingItem;
