import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import ListShared from './ListShared'
import styles from './styles.module.css'
import { markReadAll, shareSelector, getSharedTopic } from '@/redux/modules/share'
import { EXPORTS_TYPE } from '@/utils/constant'
import {
  getSharedBenchmarks,
  benchmarkSelector,
  deleteBenchmark,
  clearState,
} from '@/redux/modules/benchmark'
import {
  getSharedPieces,
  pieceSelector,
  deletePiece
} from '@/redux/modules/piecefolio'
import { useDispatch, useSelector } from 'react-redux'
import toast from 'react-hot-toast'

function index() {
  const dispatch = useDispatch()

  const { isFetching, listShared } = useSelector(shareSelector)
  
  const {
    isFetching: gettingBenchmark,
    list,
    isDeleted,
    isProcessing,
    successMessage,
    isError,
    errors,
  } = useSelector(benchmarkSelector)

  const {
    isFetching: gettingPiece,
    list: listPieceShared,
    isDeleted: isDeletedPiece,
    isProcessing: processingPiece,
    successMessage: messPiece,
    isError: errPiece,
    errors: errorsPiece,
  } = useSelector(pieceSelector)

  const handleDeleteBenchmark = (id, title) => {
    dispatch(deleteBenchmark({ benchmarkId: id, key: title }))
  }

  const handleDeletePiece = (id, title) => {
    dispatch(deletePiece({ piece_id: id, key: title }))
  }

  useEffect(() => {
    let isApiSubscribed = true

    const fetchTopics = () => {
      const controller = new AbortController()
      const signal = controller.signal
      dispatch(getSharedTopic({ signal: signal }))
    }

    const fetchBenchmarks = () => {
      const controller = new AbortController()
      const signal = controller.signal
      dispatch(getSharedBenchmarks({ signal: signal }))
    }

    const fetchPieces = () => {
      const controller = new AbortController()
      const signal = controller.signal
      dispatch(getSharedPieces({ signal: signal }))
    }

    const getData = () => {
      if (isApiSubscribed) {
        fetchTopics()
        fetchBenchmarks()
        fetchPieces()
        isApiSubscribed = false
      }
    }

    getData()
    dispatch(markReadAll())

    return () => {
      isApiSubscribed = false
    }
  }, [])

  useEffect(() => {
    if (isError) {
      const errs = transformErrors(errors)
      errs.forEach((er) => {
        if (er.fieldName !== 'status') toast.error(er.message)
      })
      dispatch(clearState())
    }

    if (successMessage) {
      toast.success(successMessage)
      dispatch(clearState())
    }
  }, [isError, successMessage])

  return (
    <Box className={`${styles.container} w-100`}>
      <ListShared data={listShared} loading={isFetching} title='共有されたAI検索結果' type={EXPORTS_TYPE[0].type}/>
      {/* <ListShared
        data={list?.shared}
        loading={gettingBenchmark}
        showAction
        closeDialog={isDeleted}
        isProcessing={isProcessing}
        onDelete={handleDeleteBenchmark}
        title='共有ベンチマーク調査'
        type={EXPORTS_TYPE[1].type}
      /> */}
      <ListShared
        data={listPieceShared?.shared}
        loading={gettingPiece}
        showAction
        closeDialog={isDeletedPiece}
        isProcessing={processingPiece}
        onDelete={handleDeletePiece}
        title='共有されたPieceFolio検索結果'
        type={EXPORTS_TYPE[2].type}
      />
    </Box>
  )
}

export default index
