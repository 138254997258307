import React, { useEffect, useState, useRef } from 'react'
import Box from '@mui/material/Box'
import { SearchBar, SharingDialog, ConfirmDialog, Loading, ExportPopup } from '@/components'
import styles from './search.module.css'
import { ACTIONS, EXPORTS, EXPORTS_TYPE, PERMISSION_VIEW } from '@/utils/constant'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  chatQuestion,
  chatSelector,
  clearState,
  getTopic,
  clearTopic,
  deleteTopic,
  clearCurrentQuestion,
} from '@/redux/modules/chat'
import { getMembersToShare, getSharedMembers } from '@/redux/modules/share'
import Question from './Question'
import toast from 'react-hot-toast'
import { onDownloadTopic } from '@/utils/function'
import { transformErrors } from '@/utils/errorHandling'
import { getUser } from '@/utils/localData'
import { TOPIC_NOT_EXIST, QUESTION_EMPTY } from '@/utils/message'
import Button from '@mui/material/Button'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { scrollTop } from '@/utils/helper'
import templateApi from '@/api/template';

function index() {
  const currentUser = getUser()
  const navigate = useNavigate()
  const scrollRef = useRef()
  const messagesEndRef = useRef(null)
  const { topicId } = useParams()
  const location = useLocation()
  const { state, pathname } = location
  const dispatch = useDispatch()

  const [isReady, setIsReady] = useState(false);

  const { topic, isCreatingQuestion, isError, errors, alertMessage, isFetching } =
    useSelector(chatSelector)
  const [chatData, setChatData] = useState()
  const [questionContent, setQuestionContent] = useState('')
  const [topicNotFound, setTopicNotFound] = useState(false)
  const [isAddNewQuestion, setIsAddNewQuestion] = useState(false)
  
  const [selectedQuestions, setSelectedQuestions] = useState([])
  const [exportTitle, setExportTitle] = useState('')
  const [drawerOpen, setDrawerOpen] = useState(false)

  const questionRefs = useRef({});
  const [selectedQuestionId, setSelectedQuestionId] = useState(null);

  const wrapperRef = useRef(null);

    const onCreateQuestion = () => {
    setIsAddNewQuestion(true)
    if (!questionContent) {
      toast.error(QUESTION_EMPTY)
      return
    }
    dispatch(chatQuestion({ question: questionContent, topic_id: topicId }))
  }

  const keyPress = (e) => {
    if (e.keyCode == 13) onCreateQuestion()
  }

  const detectHeight = () => {
    if (isAddNewQuestion) scrollRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'end' })
  }

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const onAddNewQuestion = () => {
    const newQuestionTemp = {
      question_id: null,
      question: questionContent ? questionContent : state?.questionTitle,
      created_last_name: currentUser.lastName,
      created_first_name: currentUser.firstName,
    }
    if (chatData) {
      setChatData({ ...chatData, messages: [...chatData.messages, newQuestionTemp] })
    } else {
      setChatData({ messages: [newQuestionTemp] })
    }
    setQuestionContent('')
  }

  const onToggleDrawer = (open, title) => () => {
    setDrawerOpen(open)
    setExportTitle(title)
    if (!open) {
      setSelectedQuestionId(null);
    }
  }

  const toggleSelectedQuestion = (questionId) => {
    const question = chatData.messages.find((message) => message.question_id === questionId);
  
    if (!question) return;
  
    setSelectedQuestions((prevSelected) => {
      const isSelected = prevSelected.some((q) => q.question_id === questionId);
     
      if (isSelected) {
        return prevSelected.filter((q) => q.question_id !== questionId);
      }
     
      return [...prevSelected, question];
    });
  };

  const mergeSelectedQuestions = (selectedQuestions, selected_ids) => {
    return selected_ids.map((sq) => {
      const question = selectedQuestions.find(
        (q) => q.question_id === sq.id
      );
      
      if (question) {
        return {
          ...question,
          question: sq.title,
        };
      }
      
      return null;
    }).filter(q => q !== null);
  };
  

  const onDownload = (payload) => {
    const { selected_ids, file_name } = payload;
  
    if (selected_ids.length > 0) {
      const selectedQuestions = chatData?.messages || [];

      const updatedSelectedQuestions = mergeSelectedQuestions(selectedQuestions, selected_ids);

      onDownloadTopic(updatedSelectedQuestions, file_name);
      toast.success(`${file_name} が出力されました！`);
    } else {
      toast.error('質問が選択されていません。');
    }
  };

  const onDownloadFromBE = async (payload) => {

    const { selected_ids, file_name } = payload;
  
    try {
      const response = await templateApi.downloadTemplate({
        selected_questions: selected_ids,
        file_name
      });
      
      const url = window.URL.createObjectURL(response);
  
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file_name); // Use the dynamic file name
      document.body.appendChild(link);
      link.click();
  
      link.remove();
      window.URL.revokeObjectURL(url);
      toast.success(`${file_name} が正常にダウンロードされました！`);
    } catch (error) {
      console.error('Error downloading template:', error.response ? error.response.data : error);
      toast.error(error.response ? error.response.data : error || 'テンプレートのダウンロードに失敗しました');
    }
  };

  const handleSelectAllQuestions = (selectAll) => {
    if (selectAll) {
      setSelectedQuestions(chatData?.messages || []);
    } else {
      setSelectedQuestions([]);
    }
  };

  const scrollToQuestion = (question_id) => {
    const ref = questionRefs.current[question_id];
    if (ref && ref.scrollIntoView) {
      ref.scrollIntoView({ behavior: 'smooth', block: 'start' });
      setSelectedQuestionId(question_id);
      ref.classList.add(styles.highlight);
      setTimeout(() => {
        ref.classList.remove(styles.highlight);
      }, 2000);
    }
  };

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setSelectedQuestionId(null);
    }
  };

  useEffect(() => {
    if (topic) {
      setChatData(topic)
    }
  }, [topic])

  useEffect(() => {
    if (isCreatingQuestion) {
      onAddNewQuestion()
    }
  }, [isCreatingQuestion])

  useEffect(() => {
    let isApiSubscribed = true
    const controller = new AbortController()
    const signal = controller.signal

    const fetchTopic = () => {
      if (isApiSubscribed && topicId) {
        dispatch(getTopic({ topicId: topicId, signal: signal }))
        isApiSubscribed = false
      }
    }

    scrollTop()
    fetchTopic()
    return () => {
      dispatch(clearCurrentQuestion())
      controller.abort()
      isApiSubscribed = false
    }
  }, [])

  useEffect(() => {
    if (isError) {
      const errs = transformErrors(errors)
      errs.forEach((er) => {
        if (er.message === TOPIC_NOT_EXIST) {
          setTopicNotFound(true)
        }
        if (er.fieldName !== 'status') toast.error(er.message)
      })
      dispatch(clearState())
    }

    if (alertMessage) {
      toast.success(alertMessage)
      dispatch(clearState())
      if (pathname.includes('top')) {
        navigate('/aisearch')
      } else {
        navigate('/library')
      }
    }

    setChatData({
      ...chatData,
      messages: chatData?.messages.filter((mess) => mess.question_id !== null) || [],
    })
  }, [isError, alertMessage])

  useEffect(() => {
    onAddNewQuestion()
    return () => {
      dispatch(clearTopic())
    }
  }, [])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (chatData?.messages?.length > 0 && chatData.messages.every(msg => msg.question_id)) {
      setIsReady(true);
    }
  }, [chatData?.messages]);

  useEffect(() => {
    if (state?.selectAll && state?.openExportPopup && isReady) {
      setDrawerOpen(true);
      setExportTitle(state?.exportTitle);
      setTimeout(() => {
        handleSelectAllQuestions(true);
      }, 0);
    }
  }, [isReady, state]);

  useEffect(() => {
    if (selectedQuestions.length == 0) {
      setSelectedQuestionId(null)
    }
  }, [selectedQuestions]);

  if (!chatData || isFetching) return <Loading />
  if (topicNotFound) return null
  return (
    <>
    <Box className={styles.searchPage}>
      <Box className={`${styles.searchPageContent} ${drawerOpen ? styles.scaleHorizontal : ''}`}>
        <Button
          onClick={() => navigate(-1)}
          variant='outlined'
          startIcon={<ArrowBackIcon />}
          className={`${styles.backButton} border-bottom-theme color-theme`}
        >
          <span>戻る</span>
        </Button>
        {topic.topic_id && <Actions data={chatData} onToggleDrawer={onToggleDrawer} drawerOpen={drawerOpen}/>}
        <Box ref={scrollRef} className={styles.answerWrapper}>
          {chatData.messages.map((question) => {
            return (
              <Question
                key={question.question_id}
                ref={(el) => (questionRefs.current[question.question_id] = el)}
                data={question}
                detectHeight={detectHeight}
                toggleSelectedQuestion={toggleSelectedQuestion}
                selectedQuestions={selectedQuestions}
                selectedQuestionId={selectedQuestionId}
                wrapperRef={wrapperRef}
              />
            )
          })}
          <div ref={messagesEndRef} />
          <Box className={styles.boxSearchBar}>
            <SearchBar
              value={questionContent}
              onChange={(e) => setQuestionContent(e.target.value)}
              onKeyDown={keyPress}
              placeholder='追加で何を尋ねますか？'
              containerClasses={[styles.containerSearch]}
              buttonClasses={[styles.buttonSearch]}
              inputContainerClasse={[styles.inputContainer]}
              onClickButton={onCreateQuestion}
            />
          </Box>
        </Box>
      </Box>

      <ExportPopup
        open={drawerOpen}
        onClose={() => {
          setDrawerOpen(false);
          setSelectedQuestionId(null);
        }}
        exportTitle={exportTitle}
        selectedList={selectedQuestions}
        onDownload={onDownload}
        onDownloadFromBE={onDownloadFromBE}
        fileNameDefault={chatData.title}
        type={EXPORTS_TYPE[0].type}
        onSelectAll={handleSelectAllQuestions}
        onTitleClick={scrollToQuestion}
      />
    </Box>
    </>
  )
}

export default index

const Actions = ({ data, onToggleDrawer, drawerOpen }) => {
  const dispatch = useDispatch()
  const { isProcessing } = useSelector(chatSelector)

  const { share_permission, topic_id, title } = data
  const [showSharingDialog, setShowSharingDialog] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const dropdownRef = useRef(null);

  const onToggleSharingDialog = (e) => {
    setShowSharingDialog(!showSharingDialog)
  }

  const handleCloseSharingDialog = (e) => {
    e.stopPropagation()
    setShowSharingDialog(false)
  }

  const handleDelete = () => {
    dispatch(deleteTopic({ topicId: topic_id, key: '' }))
  }

  const toggleDropdown = (e) => {
    e.stopPropagation()
    setDropdownOpen(!dropdownOpen)
  }

  const handleDropdownItemClick = (exportLabel) => () => {
    setDropdownOpen(false);
    onToggleDrawer(true, exportLabel)();
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (dropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownOpen]);

  useEffect(() => {
    if (showSharingDialog) {
      dispatch(getMembersToShare({ topicId: topic_id }))
      dispatch(getSharedMembers({ topicId: topic_id }))
    }
  }, [showSharingDialog])

  return (
    <Box className={`box-actions ${drawerOpen ? 'drawerOpen' : ''}`}>
      <ul className='bg-theme-hover-opacity'>
        {share_permission != PERMISSION_VIEW && (
          <li onClick={onToggleSharingDialog} className={`action`}>
            <Box className={`box-action`}>
              <img src={ACTIONS[0].icon} />
              <p>共有</p>
            </Box>
          </li>
        )}

        <li className={`action`}>
          <div ref={dropdownRef}>
            <Box className={`box-action`} onClick={toggleDropdown}>
              <img src={ACTIONS[1].icon} />
              <p>出力</p>
            </Box>
            {dropdownOpen && (
              <ul className={`${styles.dropdownMenu} ${share_permission != PERMISSION_VIEW ? styles.dropdownMenuShifted : styles.dropdownMenuAligned}`}>
                <li onClick={handleDropdownItemClick(EXPORTS[0].label)}>
                  <img src={EXPORTS[0].icon} />
                  <p>プレーンファイルで出力</p>
                </li>
                <li onClick={handleDropdownItemClick(EXPORTS[1].label)}>
                  <img src={EXPORTS[1].icon} />
                  <p>テンプレートを使用して出力</p>
                </li>
              </ul>
            )}
          </div>
        </li>

        {share_permission != PERMISSION_VIEW && (
          <li onClick={() => setOpenDialog(true)} className={`action`}>
            <Box className={`box-action`}>
              <img src={ACTIONS[2].icon} />
              <p>削除</p>
            </Box>
          </li>
        )}
      </ul>
      <SharingDialog
        show={showSharingDialog}
        handleClose={handleCloseSharingDialog}
        topicId={topic_id}
        title={title}
      />
      <ConfirmDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        text='このトピックを削除してもよろしいでしょうか？'
        onAgree={handleDelete}
        title='確認'
        isProcessing={isProcessing}
      />
    </Box>
  )
}
