import React, { useState, useEffect, useRef } from 'react'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import useTheme from '@/hooks/useTheme'
import { RadioGroupButton, FormLayout, InputWithLabel, Countdown } from '@/components'
import { useSelector, useDispatch } from 'react-redux'
import { updateUser, updatePassword, userSelector, clearState, getUser, clearIsLoaded } from '@/redux/modules/user'
import { notifications, themes } from '@/utils/generateOptions'
import toast from 'react-hot-toast'
import styles from './settingUser.module.css'
import { changeTheme } from '@/utils/function'
import { PURPLE, GREEN, BLUE, GREEN_NUM, PURPLE_NUM } from '@/utils/constant'
import { scrollTop } from '@/utils/helper'

function index() {
  const optionsNotification = notifications(useTheme().radioChecked)
  const optionsTheme = themes()

  const dispatch = useDispatch()
  const user = useSelector(userSelector)
  const { isProcessing, isLoaded, isSuccessfully, errors, successMessage } = user
  const { isProcessingChangePass, isSuccessfullyChangePass, errorsChangePass, successMessageChangePass } = user

  const [userData, setUserData] = useState(null)
  const [error, setError] = useState(null)
  const [password, setPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordConfirmed, setNewPasswordConfirmed] = useState('')

  const firstNameRef = useRef(null)
  const lastNameRef = useRef(null)
  const firstNameKatakanaRef = useRef(null)
  const lastNameKatakanaRef = useRef(null)
  const phoneNumberRef = useRef(null)
  const passwordRef = useRef(null)
  const passwordConfirmedRef = useRef(null)
  const newPasswordRef = useRef(null)
  const newPasswordConfirmedRef = useRef(null)

  const elementsRef = useRef({
    firstNameRef,
    lastNameRef,
    firstNameKatakanaRef,
    lastNameKatakanaRef,
    phoneNumberRef,
    passwordRef,
    passwordConfirmedRef,
    newPasswordRef,
    newPasswordConfirmedRef,
  })

  const validateEmpty = () => {
    const { firstName, lastName, firstNameKatakana, lastNameKatakana } = userData
    let isHaveEmpty = false
    if (!firstName) {
      elementsRef.current.firstNameRef.current.focus()
      setError((prevstate) => ({ ...prevstate, firstName: '名は必須項目です。' }))
      isHaveEmpty = true
    }
    if (!lastName) {
      elementsRef.current.lastNameRef.current.focus()
      setError((prevstate) => ({ ...prevstate, lastName: '姓は必須項目です。' }))
      isHaveEmpty = true
    }
    if (!firstNameKatakana) {
      elementsRef.current.firstNameKatakanaRef.current.focus()
      setError((prevstate) => ({
        ...prevstate,
        firstNameKatakana: '名（フリガナ）は必須項目です。',
      }))
      isHaveEmpty = true
    }
    if (!lastNameKatakana) {
      elementsRef.current.lastNameKatakanaRef.current.focus()
      setError((prevstate) => ({
        ...prevstate,
        lastNameKatakana: '姓（フリガナ）は必須項目です。',
      }))
      isHaveEmpty = true
    }

    return isHaveEmpty
  }

  const onUpdateUser = () => {
    if (validateEmpty()) return
    const {
      firstName,
      lastName,
      firstNameKatakana,
      lastNameKatakana,
      phoneNumber,
      receiveNotification,
      theme,
    } = userData
    let newData = {
      firstName,
      lastName,
      firstNameKatakana,
      lastNameKatakana,
      phoneNumber,
      receiveNotification: receiveNotification == 0 ? 0 : Number(receiveNotification),
      theme: theme == 0 ? 0 : Number(theme),
    }
    newData = {
      ...newData,
      password: null,
      passwordConfirmed: null,
    }
    dispatch(updateUser(newData))
  }

  const onChangeTheme = (e) => {
    let theme = BLUE
    if (e.target.value == GREEN_NUM) {
      theme = GREEN
    } else if (e.target.value == PURPLE_NUM) {
      theme = PURPLE
    }
    changeTheme(theme)
    setUserData({ ...userData, theme: e.target.value })
  }

  useEffect(() => {
    if (user && isLoaded) {
      setUserData(user)
      dispatch(clearIsLoaded())
    }
  }, [user, isLoaded])

  useEffect(() => {
    if (isSuccessfully && !isProcessing) {
      toast.success(successMessage)
      dispatch(clearState())
    }
  }, [isSuccessfully, isProcessing])

  useEffect(() => {
    if (errors) {
      if (errors.message) toast.error(errors.message)
      else setError(errors)
    }
  }, [errors])

  useEffect(() => {
    let isApiSubscribed = true

    const cleanUp = () => {
      isApiSubscribed = false
      dispatch(clearState())
    }
    const fetchUser = () => {
      if (isApiSubscribed) {
        dispatch(getUser())
        cleanUp()
      }
    }

    scrollTop()
    fetchUser()
    return () => {
      cleanUp()
    }
  }, [])

  const validateEmptyChangePassword = () => {
    let isHaveEmpty = false
    if (!password) {
      elementsRef.current.passwordRef.current.focus()
      setError((prevstate) => ({ ...prevstate, password: 'パスワードは必須項目です。' }))
      isHaveEmpty = true
    }
    if (!newPassword) {
      elementsRef.current.newPasswordRef.current.focus()
      setError((prevstate) => ({ ...prevstate, newPassword: '新しいパスワードは必須項目です。' }))
      isHaveEmpty = true
    }
    if (!newPasswordConfirmed) {
      elementsRef.current.newPasswordConfirmedRef.current.focus()
      setError((prevstate) => ({
        ...prevstate,
        newPasswordConfirmed: 'パスワード（確認用）は必須項目です。',
      }))
      isHaveEmpty = true
    }
    return isHaveEmpty
  }

  const onUpdatePassword = () => {
    if (validateEmptyChangePassword()) return
    dispatch(
      updatePassword({
        password: password,
        newPassword: newPassword,
        newPasswordConfirmed: newPasswordConfirmed,
      })
    )
  }

  const resetForm = () => {
    setPassword('')
    setNewPassword('')
    setNewPasswordConfirmed('')
  }

  useEffect(() => {
    if (isSuccessfullyChangePass && !isProcessingChangePass) {
      toast.success(successMessageChangePass)
      dispatch(clearState())
      resetForm()
    }
  }, [isSuccessfullyChangePass, isProcessingChangePass])

  useEffect(() => {
    if (errorsChangePass) {
      if (errorsChangePass.message) toast.error(errorsChangePass.message)
      else setError(errorsChangePass)
    }
  }, [errorsChangePass])

  useEffect(() => {
    scrollTop()
    return () => {
      dispatch(clearState())
    }
  }, [])


  return (
    <Box>
      <FormLayout
        title='プロフィール設定'
        onClick={onUpdateUser}
        disableButton={!userData}
        isProcessing={isProcessing}
      >
        <Box className={styles.form}>
          {userData ? (
            <>
              <Box className={`${styles.row} ${styles.inline}`}>
                <InputWithLabel
                  inputRef={elementsRef.current.lastNameRef}
                  label='姓'
                  placeholder='山田'
                  value={userData.lastName || ''}
                  onChange={(e) => setUserData({ ...userData, lastName: e.target.value })}
                  error={error && !!error['lastName']}
                  errorText={(error && error['lastName']) || ''}
                  styleContainer={{ width: '18.5rem', flex: 'none' }}
                  required
                />
                <InputWithLabel
                  inputRef={elementsRef.current.firstNameRef}
                  label='名'
                  placeholder='太郎'
                  value={userData.firstName || ''}
                  onChange={(e) => setUserData({ ...userData, firstName: e.target.value })}
                  error={error && !!error['firstName']}
                  errorText={(error && error['firstName']) || ''}
                  styleContainer={{ width: '18.5rem', flex: 'none' }}
                  required
                />
              </Box>
              <Box className={`${styles.row} ${styles.inline}`}>
                <InputWithLabel
                  inputRef={elementsRef.current.lastNameKatakanaRef}
                  label='姓（フリガナ）'
                  placeholder='ヤマダ'
                  value={userData.lastNameKatakana || ''}
                  onChange={(e) => setUserData({ ...userData, lastNameKatakana: e.target.value })}
                  error={error && !!error['lastNameKatakana']}
                  errorText={(error && error['lastNameKatakana']) || ''}
                  styleContainer={{ width: '13.1875rem', flex: 'none' }}
                  required
                />
                <InputWithLabel
                  inputRef={elementsRef.current.firstNameKatakanaRef}
                  label='名（フリガナ）'
                  placeholder='タロウ'
                  value={userData.firstNameKatakana || ''}
                  onChange={(e) => setUserData({ ...userData, firstNameKatakana: e.target.value })}
                  error={error && !!error['firstNameKatakana']}
                  errorText={(error && error['firstNameKatakana']) || ''}
                  styleContainer={{ width: '13.1875rem', flex: 'none' }}
                  required
                />
              </Box>
              <Box className={styles.row}>
                <InputWithLabel
                  inputRef={elementsRef.current.phoneNumberRef}
                  label='電話番号'
                  placeholder='電話番号を入力'
                  value={userData.phoneNumber || ''}
                  onChange={(e) => setUserData({ ...userData, phoneNumber: e.target.value })}
                  error={error && !!error['phoneNumber']}
                  errorText={error && error['phoneNumber']}
                  styleContainer={{ width: '32.6875rem', flex: 'none' }}
                />
              </Box>
              <Box className={`${styles.row} ${styles.notification}`}>
                <label className={`${styles.label}`}>共有があった際通知を受け取る：</label>
                <RadioGroupButton
                  value={userData.receiveNotification}
                  options={optionsNotification}
                  className={[styles.radios]}
                  name='notifications'
                  onChange={(e) => setUserData({ ...userData, receiveNotification: e.target.value })}
                />
              </Box>
              <Box className={`${styles.row} ${styles.theme}`}>
                <label className={`${styles.label}`}>テーマを設定：</label>
                <RadioGroupButton
                  value={userData.theme}
                  options={optionsTheme}
                  className={[styles.radios]}
                  onChange={onChangeTheme}
                  name='theme'
                />
              </Box>
            </>
          ) : (
            <>
              {Array(5)
                .fill(null)
                .map((_, i) => {
                  return <Skeleton key={`ske-${i}`} variant='rounded' height={65} />
                })}
            </>
          )}
        </Box>
      </FormLayout>
      <FormLayout
        title='パスワード変更'
        onClick={onUpdatePassword}
        disableButton={isProcessingChangePass}
        isProcessing={isProcessingChangePass}
        paddingTop='0'
      >
        <Box className={styles.form}>
          <Box className={styles.row}>
            <InputWithLabel
              type='password'
              inputRef={elementsRef.current.passwordRef}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              label='現在のパスワード'
              placeholder='現在のパスワードを入力'
              error={error && !!error['password']}
              errorText={(error && error['password']) || ''}
              styleContainer={{ flex: 2 }}
            />
          </Box>
          <Box className={styles.row}>
            <InputWithLabel
              type='password'
              inputRef={elementsRef.current.newPasswordRef}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              label='新しいパスワード'
              placeholder='パスワードを入力'
              error={error && !!error['newPassword']}
              errorText={(error && error['newPassword']) || ''}
              strengBar
              styleContainer={{ flex: 2 }}
            />
          </Box>
          <Box className={styles.row}>
            <InputWithLabel
              type='password'
              inputRef={elementsRef.current.newPasswordConfirmedRef}
              value={newPasswordConfirmed}
              onChange={(e) => setNewPasswordConfirmed(e.target.value)}
              label='新しいパスワード（確認用）'
              placeholder='パスワードを入力'
              error={error && !!error['newPasswordConfirmed']}
              errorText={(error && error['newPasswordConfirmed']) || ''}
              styleContainer={{ flex: 2 }}
            />
          </Box>
        </Box>
      </FormLayout>
    </Box>
  )
}

export default index
